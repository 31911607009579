<template>
  <div class="page">
    <van-nav-bar
      fixed
      :title="pageTitle"
      left-arrow
      @click-left="onClickLeft"
    />
    <div v-if="planDetail && planDetail.length" class="plan-all">
      <div
        v-for="(item, index) in planDetail"
        :key="index"
        class="plan-content"
      >
        <div v-for="(ele, i) in item.list" :key="ele.id" class="plan-detail">
          <div class="plan-top">
            <span class="plan-title">{{ ele.name }}</span>
            <span class="items" :class="!index && !i ? 'first-item' : ''">{{
              item.name
            }}</span>
          </div>
          <div class="plan-bottom">
            <img class="second-icon" src="../../../assets/images/jop-tag.png" />
            <span class="date-text">{{
              ele.list && ele.list.length
                ? ele.list.map(e => e.name).join(",")
                : ""
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <p v-else style="text-align: center; margin: 40px 0 0; font-size: 14px;">
      暂无数据
    </p>
  </div>
</template>

<script>
import messageApi from "@/api/message";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "AlarmDetail",
  data() {
    return {
      pageTitle: "工艺处置队",
      planDetail: []
    };
  },
  mounted() {
    this.initPage();
  },
  created() {
    this.readMessage();
  },
  methods: {
    initPage() {
      this.planDetail = this.$route.params.data.list;
      this.pageTitle = this.$route.params.data.name;
    },
    onClickLeft() {
      history.go(-1);
    }
  }
};
</script>

<style scoped lang="scss">
.page {
  text-align: left;
  height: 100vh;
  background: #fff;
  font-family: PingFangSC;
}
.plan-all {
  overflow: auto;
  padding: 58px 0 0;
  .plan-content {
    width: 100%;
  }
  // display: flex;
  .plan-detail {
    width: calc(100% - 32px);
    margin: 14px 16px 0;
    padding-bottom: 14px;
    border-bottom: 1px solid #e1e3e8;
    .plan-top {
      margin-bottom: 10px;
      height: 25px;
      .plan-title {
        font-size: 14px;
        color: #2e2e4d;
        font-weight: bold;
      }
      .items {
        margin-left: 8px;
        background-color: #1676ff20;
        color: #1676ff;
        border-radius: 2px;
        padding: 4px 6px;
        font-size: 12px;
      }
      .first-item {
        background-color: #ef8c1020;
        color: #ef8c10;
      }
    }
    .plan-bottom {
      color: #a0a2a6;
      margin-left: 1px;
      display: flex;
      .date-text {
        margin-left: 8px;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
.second-icon {
  height: 14px;
  position: relative;
  top: 3px;
}
</style>
